.PastOrderfetch{
  display: flex;
}

.order{
width: 100%;
height: 25px;
text-align: left;
color: #1E2022;
text-transform: capitalize;
display: flex;
}

h4{
  margin: 0px;
  padding: 0px;
}

.pastOrder{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}



.pastorder-full-container {
  position: absolute;
  top: 3em;
  left: 5em;
  height: calc(100vh - 70px);
  /*overflow-y: scroll;*/
}

.table-order-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  margin: 1em;
  margin-top: 20px;
  /* border: 1px solid black; */
}
.table-create-search {
  margin-top: 0%;
  display: flex;
  width: 32%;
  align-items: center;
  /* justify-content: space-between; */
}
.column {
  width: 9vw;
  /*height: 100%;*/
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: 0px;

}
.search-bar {
  margin: 0.8em;
  height: 32px;
  border-bottom: 1px solid #a0a0a0;
}
.search-bar > input {
  /* border: none; */
  outline: none;
}
.tablePast {
  position: relative;
  width: 100%;
}

.orders-table-header {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #383737;
  /* padding: 1em; */
  color: #ffffff;
}

.past-order-table-wrapper {
  /*overflow-y: scroll;*/
  margin-right: 10px;
  margin-bottom: 20px;
}

#past-order-rows-wrapper {
  max-height: 600px;
  overflow-y: scroll;
}

.column > h6 {
  font-size: 14px;
}
.column > p {
  font-size: 14px;
  color: #1e2022;
  font-weight: 500;
}
.create-button {
  color: #5861ae;
  background-color: #ffffff;
  border: 1px solid #5861ae;
  width: 8em;
  height: 2em;
  border-radius: 4px;
}
.create-button:hover{
  background-color: #5861ae;
  color: white;
}
.details-container1 {
  height: 50px;
  /* padding: 0 1em; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eaedf0;
  border: 1px solid #eaedf0;
}
.details-container2 {
  height: 50px;
  /* padding: 0 1em; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #eaedf0;
}

#cancel-text-cell {
  cursor: pointer;
  color: #EB1717;
  font-size: 12px;
}

.column > p > img {
  width: 20px;
  cursor: pointer;
}

.search-bar input {
  border: none;
}

.details-container1:nth-child(odd){
  background-color: white;
}
.details-container1:nth-child(even){
  background-color: #EAEDF0;
}
/* h4,
p {
  text-align: left;
} */
/* .details-container1 > * { */
/* text-align: center;
  width: 128.2px;
} */
/* .details-container2 > * {
  text-align: center;
  width: 128.2px;
} */
/* .orders-table-header > * {
  width: 128.2px;
  text-align: center;
} */

