.order-success {
    box-sizing: border-box;
    width: 460px;
    height: 500px;
    background-color: white;
    border-radius: 8px;
    padding: 62px 100px;
    display: flex;
    flex-flow: column;
    gap: 20px;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    color: #0A1F44;
    font-size: 17px;
}

.check-icon {
    border-radius: 50%; 
    width: 120px;
    height: 120px;
    padding: 10px;
    color: #5861AE;
    font-weight: 900;
    font-size: 80px;
    background-color: #5861AE60;
}

.order-success div:nth-child(2) {
    font-size: 24px;
    font-weight: 600;
}

.order-success button {
    border-radius: 22px;
    background-color: #5861AE;
    color: white;
    font-size: inherit;
    width: 230px;
    height: 44px;
    border: none;
    cursor: pointer;
}
