#header{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 57px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 48px #0000000F;
    opacity: 1;
    display: flex;
    justify-content: space-between;
}
.heading{
    /* top: 20px; */
    margin-top: -10px;
    margin-left: 30px;
    width: 108px;
    height: 27px;
    /* UI Properties */
    text-align: left;
    
    letter-spacing: 1.8px;
    color: #5861AE;
    text-transform: uppercase;
    /* opacity: 1; */
}
.list1 ,.list2,.list3{

    border: 0.8px solid lightgray;
    
}
#rigth-head{
    display: flex;
    height: 57px;
}
.list1{
    padding-left: 20px;
    padding-right: 20px;
    color: #565657;
}
.list2{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #565657;
    
}
.list3{
    display: flex;
    padding-right: 30px;
    background: #5861AE 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    float: right;
}

.heading h2 {
    cursor: pointer;
}

.logout {
    cursor: pointer;
}
