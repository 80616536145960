.signin-cont {
    display: flex;
    /* padding-bottom: 40px; */
    width: 100%;
    height: 100%;
}

.signin-cont-left {
    width: 50%;
    /* padding-top: 150px;
    padding-bottom: 159px; */
    /* padding-left: 152px; */
    position: relative;
    left: 180px;
    top: 100px;
}

.signin-cont-right {
    width: 50%;
    margin-top: 40px;
    height: 600px;
}

.signin-cont-laundryhead {
    width: 500px;
    font: normal normal 700 80px/100px Avenir;
    letter-spacing: 0px;
    color: #5861ae;
    text-transform: capitalize;
    opacity: 1;
}

.signin-cont-description {
    text-align: left;
    font: normal normal normal 20px/26px Avenir;
    margin-top: 14px;
    letter-spacing: 0.8px;
    color: #565657;
    text-transform: capitalize;

    opacity: 1;
}

.signin-cont-noacc {
    margin-bottom: 16.5px;
    margin-top: 74px;
    text-align: left;
    font: normal normal normal 16px/26px Avenir;
    letter-spacing: 0.64px;
    color: #565657;
    text-transform: capitalize;
    opacity: 1;
}

.signin-cont-regisbut {
    cursor: pointer;
    width: 150px;
    text-align: center;
    font: normal normal bold 15px/20px Open Sans;
    letter-spacing: 0.3px;
    color: #4552c1;
    opacity: 1;
    border: 2px solid #4552c1;
    border-radius: 4px;
    opacity: 1;
    padding-top: 14px;
    padding-left: 43px;
    padding-bottom: 16px;
    background: white;
    padding-right: 43px;
}

.signin-cont-signinhead {
    text-align: left;
    /* font: normal normal medium 32px/60px Avenir; */
    font-family: open-sans, sans-serif;

    font-size: 32px;
    line-height: 60px;
    letter-spacing: 1.28px;
    color: #4552c1;
    text-transform: uppercase;
    opacity: 1;
  
        font-weight: 500;
    padding-top: 130px;
}

.signin-cont-right {
    position: relative;
    bottom: 40px;
    background: #b9c0fd1a 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-left: 130px;
}

.signin-form {
    margin-top: 65px;
}

.signin-button {
    position: relative;
    cursor: pointer;
    top: 10px;
    left: 80px;
    width: 150px;
    height: 50px;
    background: #4552c1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 15px/20px Open Sans;
    letter-spacing: 0.3px;
    color: #ffffff;
    border: none;
}

.inp {
    position: relative;
    font-size: 17px;
    border-top: 20px solid transparent;
    margin-bottom: 5px;
    --field-padding: 12px;
}

.inp input {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: var(--field-padding);

    width: 370px;
    outline: none;
    font-size: 14px;
}

.inp .placeholder {
    position: absolute;
    left: var(--field-padding);
    width: calc(100% - (var(--field-padding) * 2));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 22px;
    line-height: 100%;
    transform: translateY(-50%);
    color: #aaa;
    transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
    padding-bottom: 25px;
}

.inp input.dirty+.placeholder,
.inp input:focus+.placeholder,
.inp input:not(:placeholder-shown)+.placeholder {
    top: -10px;
    font-size: 12px;
    letter-spacing: 0.42px;
    color: #5861ae;
}

.inp.two input {
    border-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: linear-gradient(90deg, #5861ae, #5861ae) center bottom/0 0.15em no-repeat,
        linear-gradient(90deg, #ccc, #ccc) left bottom/100% 0.15em no-repeat,
        linear-gradient(90deg, #f8f9ff, #f8f9ff) left bottom/100% no-repeat;
    transition: background-size 0.3s ease;
}

.inp.two input.dirty,
.inp.two input:not(:placeholder-shown),
.inp.two input:focus {
    background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.signin-fpass {
    padding-left: 230px;
    margin-top: 20px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    font-family: open-sans, sans-serif;
    letter-spacing: 0.64px;
    color: #4552c1;
    text-transform: capitalize;
    opacity: 1;
}

/* .padlock-image-signin {
    width: 15px;
    height: 15px;
    margin-top: -10px;
    z-index: 8;
} */

.lock{
    cursor: pointer;
    
}
